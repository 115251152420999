import {
    Box,
    Container,
    Icon,
    SimpleGrid,
    Square,
    Stack,
    Text,
} from '@chakra-ui/react'
import { data } from './_data'
import { Headline } from '../../-components/Headline'

export const Collaboration = ({ subheading }) => (
    <Box as="section" id="collaboration" bg="gray.50" px={{ base: 3 }}>
        <Container
            py={{
                base: '20',
                md: '36',
            }}
        >
            <Stack
                spacing={{
                    base: '16',
                    md: '24',
                }}
            >
                <Box mx="auto">
                    <Headline
                        heading="Designed for collaboration"
                        subheading={subheading || "Rarchy makes collaborating on website projects a breeze, whether you're working with just a few stakeholders, a large team, or with multiple clients."}
                    />
                </Box>
                <SimpleGrid
                    columns={{
                        base: 1,
                        md: 2,
                        lg: 3,
                    }}
                    columnGap={8}
                    rowGap={{
                        base: 10,
                        md: 16,
                    }}
                >
                    {data?.map((feature) => (
                        <Stack
                            key={feature.name}
                            spacing={{
                                base: '4',
                                md: '5',
                            }}
                        >
                            <Square
                                size={{
                                    base: '10',
                                    md: '12',
                                }}
                                bg="accent"
                                color="fg.inverted"
                                borderRadius="lg"
                            >
                                {typeof feature?.icon !== 'string' ? <Icon
                                    as={feature.icon}
                                    boxSize={{
                                        base: '5',
                                        md: '6',
                                    }}
                                /> :
                                    <Text fontSize="2xl" h="40px">
                                        {feature?.icon}
                                    </Text>
                                }
                            </Square>
                            <Stack
                                spacing={{
                                    base: '1',
                                    md: '2',
                                }}
                                flex="1"
                            >
                                <Text
                                    fontSize={{
                                        base: 'lg',
                                        md: 'xl',
                                    }}
                                    fontWeight="medium"
                                    color="fg.default"
                                >
                                    {feature.name}
                                </Text>
                                <Text color="fg.muted">{feature.description}</Text>
                            </Stack>
                        </Stack>
                    ))}
                </SimpleGrid>
            </Stack>
        </Container>
    </Box>
)