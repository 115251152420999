import {
    Avatar,
    Box,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Link as ChakraLink,
    DarkMode,
    HStack,
    Icon,
    Stack,
    Text
} from '@chakra-ui/react'
import { hubspot, three, twentysix } from './_logos'

import { Container } from '../../-components/Container'
import { Headline } from '../../-components/Headline'
import Image from 'next/image'
import { InView } from 'react-intersection-observer'
import Link from 'next/link'

const testimonials = [
    {
        id: 'hubspot',
        href: "https://hubspot.com",
        logo: hubspot,
        quote:
            "Globally, we publish a lot of content and our blog alone has over 30,000 pages. Using Rarchy has been invaluable when mapping out the architecture of regional content projects, and helping me to visualise them to internal stakeholders as part of the wider website.",
        name: 'Elissa Hudson',
        role: 'Senior Marketing Manager, APAC',
        image: 'https://help.rarchy.com/hubfs/Website/Testimonial%20Thumbnails/hubspot.jpg',
    },
    {
        id: 'twentysix',
        href: "https://26-agency.com",
        logo: twentysix,
        quote:
            "As a full service digital agency, we're always working on website builds, migrations, and UX projects. All of these require different areas of expertise and Rarchy helps bring all those people together in one tool.",
        name: 'Scott Tehrani',
        role: 'Director of Acquisition',
        image: 'https://help.rarchy.com/hubfs/Website/Testimonial%20Thumbnails/twentysix.jpg',
    },
    {
        id: 'three',
        href: "https://three.co.uk",
        logo: three,
        quote:
            "When we're working on new sections of the website, Rarchy helps us visualise our architecture and ensure everyone is collaborating in one place. It's become one of my favourite tools.",
        name: 'Andrew Scarre',
        role: 'Senior Product Owner',
        image: 'https://help.rarchy.com/hubfs/Website/Testimonial%20Thumbnails/ghd.jpg',
    }
]

function Testimonial(props) {
    const { id, href, logo, quote, image, name, role, ...rest } = props
    return (
        <InView triggerOnce>
            {({ inView, ref, entry }) => (
                <Box ref={ref} /* opacity={inView ? 1 : 0} */ transition="500ms">
                        <Card
                            variant='outline'
                            color='white'
                            padding={{ base: '2', lg: '4' }}
                            borderRadius='3xl'
                            backgroundColor='whiteAlpha.200'
                            borderColor='whiteAlpha.300'
                            {...rest}
                        >
                            <CardHeader pt={3} pb={1.5}>
                                    <Icon as={logo} width='auto' height={id === 'hubspot' ? 8 : 10} color='whiteAlpha.800' />
                            </CardHeader>
                            <CardBody>
                                <Text as='blockquote' fontSize="lg">{quote}</Text>
                            </CardBody>
                            <CardFooter>
                                <HStack spacing='4'>
                                    <Avatar src={image} name={name} />
                                    <Stack spacing='1'>
                                        <Text fontSize='md' lineHeight="normal">
                                            {name}
                                        </Text>
                                        <Text fontSize='md' color='whiteAlpha.800'>
                                            {role}
                                        </Text>
                                    </Stack>
                                </HStack>
                            </CardFooter>
                        </Card>
                </Box>
            )}
        </InView>
    )
}

export const Testimonials = () => {
    return (
        <DarkMode>
            <Box
                as='section'
                id='testimonials'
                position='relative'
                py='6rem'
                pb='9em'
                // bgColor="blue.600"
                backgroundImage='linear-gradient(to right, blue.600, blue.500)'
            >
                <Container>
                    <Stack
                        maxW={{ base: 'full', md: '6xl' }}
                        mx='auto'
                        py={{ base: '0', lg: "12" }}
                        direction={{ base: 'column', lg: 'row' }}
                        spacing={{ base: '20', lg: '24' }}
                    >
                        <Stack
                            spacing='10'
                            position={{ lg: 'sticky' }}
                            top={{ lg: 36 }}
                            height={{ base: 'initial', md: '18.75rem' }}
                            color='fg.default'
                        >
                            <Headline
                                heading='Teams like yours love Rarchy'
                                subheading={
                                    <Stack spacing={3} color="whiteAlpha.900" fontSize="lg">
                                        <Box>
                                            From agencies to internal teams, Rarchy's powerful sitemap and user flow tools have streamlined website planning for thousands of companies all over the world. </Box>
                                        <Box>But don’t just take our word for it – here’s what some of our customers have to say.</Box>
                                    </Stack>
                                }
                                textAlign="left"
                            />

                            <Box>
                                <Link target="_blank" href="https://www.capterra.com/reviews/203062/Rarchy?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge">
                                    <Image border="0" width="135" height="135" alt="Capterra Badge" src="https://assets.capterra.com/badge/b24c6cb6a5359ea3fc01d83d853ca690.svg?v=2145314&p=203062" style={{ objectFit: 'contain' }} />
                                </Link>
                            </Box>
                        </Stack>

                        <Stack
                            maxWidth={{ base: 'unset', lg: '34rem' }}
                            marginX='auto'
                            position='relative'
                            spacing='16'
                        >
                            {testimonials.map((testimonial) => (
                                <Testimonial
                                    key={testimonial.id}
                                    id={testimonial.id}
                                    href={testimonial.href}
                                    logo={testimonial.logo}
                                    quote={testimonial.quote}
                                    name={testimonial.name}
                                    role={testimonial.role}
                                    image={testimonial.image}
                                />
                            ))}
                        </Stack>

                    </Stack>
                </Container>
            </Box>
        </DarkMode>
    )
}