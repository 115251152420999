import { RepeatClockIcon } from '@chakra-ui/icons'
import { BsFolderFill, BsShareFill } from 'react-icons/bs'
import { MdWorkspaces } from "react-icons/md";
import { FaWandMagicSparkles } from 'react-icons/fa6';


export const data = [
    {
        name: 'Real-time collaboration',
        description: 'Simultaneously make edits, add comments, and otherwise collaborate in real time.',
        icon: FaWandMagicSparkles,
    },
    {
        name: 'Comments and @mentions',
        description: 'Gather page feedback and tag your collaborators to keep them informed of future plans.',
        icon: '@',
    },
    {
        name: 'Shared workspace',
        description: 'Invite as many users to your account as you need, with shared access to all of our products.',
        icon: MdWorkspaces,
    },
    {
        name: 'Folders with permissions',
        description: "Easily group projects and keep private if they're not ready to be shared with the whole team yet.",
        icon: BsFolderFill,
    },
    {
        name: 'Project sharing',
        description: 'Invite everyone to collaborate on your projects. No sign-in required for view-only sharing.',
        icon: BsShareFill,
    },
    {
        name: 'Version history',
        description: 'View a full timeline of all changes made to your projects, by all users. Restore changes in one click.',
        icon: RepeatClockIcon,
    },
]